import React, { useState } from "react";
import SEO from "../../../components/SEO";
import Layout from "../../../components/Layout";
import DriverManagement from "./DriverManagement";
import ModalDriverRegister from "../components/ModalDriverRegister";
import { navigate } from "gatsby";
import "./style.css";
import { FiPlus } from "react-icons/fi";
import TabArrow from "../components/TabArrow";

const DriversFreights = () => {
    const [openTab, setOpenTab] = useState("Situação");
    const [openModal, setOpenModal] = useState(false);

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const statsDefault = {
        number: 8,
        data: [
            {
                name: "Em cadastro",
                qtd: 2,
            },
            {
                name: "Em validação",
                qtd: 4,
            },
            {
                name: "Habilitados",
                qtd: 1,
            },
            {
                name: "Expirados",
                qtd: 1,
            },
        ],
    };

    return (
        <Layout
            style={{
                minHeight: "unset",
                height: "calc( 100vh - 68px )",
                overflow: "hidden",
            }}
        >
            <ModalDriverRegister
                actionModal={closeModal}
                openModal={openModal}
                handleSuccess={() => {
                    closeModal();
                    navigate("/gestao-de-motoristas/kanban");
                }}
            />
            <SEO title="Motoristas" />
            <div className="kanbans m-4">
                <header className="flex justify-between">
                    <div className="flex items-center">
                        <div className="flex">
                            <TabArrow text="Situação" stats={statsDefault} openTab={openTab} setOpenTab={setOpenTab} />
                        </div>
                    </div>
                    <button
                        onClick={actionModal}
                        className="flex items-center p-2 m-1 ml-2 bg-roxo_oficial rounded-md text-white outline-none"
                    >
                        <i>
                            <FiPlus className="w-8 h-8" />{" "}
                        </i>
                        <span>Novo motorista</span>
                    </button>
                </header>
                {openTab === "Situação" && <DriverManagement />}
            </div>
        </Layout>
    );
};

export default DriversFreights;
